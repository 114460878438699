import * as React from "react"
// import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container, Navbar, Nav } from 'react-bootstrap';
import piscatelliLogo from "../images/piscatelli-logo-reg.png"

import Scrollspy from 'react-scrollspy'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Header = ({ siteTitle }) => (
  <header className="fixed-top">
    <Container>
      
      <Navbar expand="md" collapseOnSelect={true}>
            <Link to="/">
            <img src={piscatelliLogo} alt={ siteTitle } width="693" height="83" className="maxLogoWidth" />
            </Link>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive" className="justify-content-center">
              <Nav>
              {/* <ul className="navbar-nav">
            
                <li><Link to="/about/" className="nav-link" activeClassName="active">About</Link></li>
                <li><Link to="/podcast/" className="nav-link" activeClassName="active">Podcast</Link></li>
                <li><Link to="/contact/" className="nav-link" activeClassName="active">Contact</Link></li>
              </ul> */}

              <ul className="navbar-nav">
                   <Scrollspy 
                   offset={-1}
                   items={['overview', 'projects', 'testimonials', 'contact']}
                   className="navbar-nav" currentClassName="active">
                     <li><a href="#overview" className="nav-link">Overview</a></li>
                     <li><a href="#projects" className="nav-link">Projects</a></li>
                     <li><a href="#testimonials" className="nav-link">Testimonials</a></li>
                     <li><a href="#contact" className="nav-link">Contact</a></li>
                   </Scrollspy>
                 </ul>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Container>
  </header>
)

export default Header
